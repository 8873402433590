import React, { useContext, useEffect, useRef, useState } from "react";
import { GridContext, headers, StartingIndexContext } from "../Layout/Layout";
import GridBox from "./GridBox";
import { IDataType } from "../exports/interfaces";
import { Box, CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { toast } from "react-toastify";

const GridView = (props: {
  gridDataSize: number;
  setGridDataSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { gridData, getGridData } = useContext(GridContext);
  const [items, setItems] = useState<IDataType[]>(gridData?.data);
  const [hasMore, setHasMore] = useState(true);
  const { startingIndex } = useContext(StartingIndexContext);
  // const [gridDataSize, setGridDataSize] = useState<number>(9);
  // const [page, setPage] = useState<number>(1);
  // const [startIndex, setStartIndex] = useState<number>();

  // const getIndex = () => {
  //   if(startIndex){

  //   }
  // }

  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: scrollableDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getMoreGridData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${
          props.gridDataSize > 9 ? props.gridDataSize : 9
        }&pagination[page]=${isScrollBottom ? 2 : props.page + 1}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setItems((newItems) => newItems?.concat(res?.data?.data));
        setIsScrollBottom(false);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    if (items?.length >= gridData?.meta?.pagination?.total) {
      setHasMore(false);
      return;
    }

    props.setPage((page) => page + 1);

    setTimeout(() => {
      getMoreGridData();
    }, 1000);
  };

  useEffect(() => {
    setItems(gridData?.data);
  }, [gridData]);

  useEffect(() => {
    if (startingIndex) {
      scrollToTop();
      props.setGridDataSize(startingIndex > 9 ? startingIndex : 9);
      startingIndex > 9 && setIsScrollBottom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingIndex]);

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridDataSize]);

  useEffect(() => {
    if (isScrollBottom && items) {
      scrollToBottom();
    }
  }, [isScrollBottom, items]);


  return (
    items && (
      <Box
        id="scrollableDiv"
        ref={scrollableDivRef}
        sx={{
          height: "calc(100vh - 140px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "1px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "&::-webkit-scrollbar-button": {
            display: "none",
          },
        }}
      >
        <InfiniteScroll
          dataLength={items?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "35px",
                flexWrap: "wrap",
              }}
            >
              <CircularProgress />
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              gap: "15px",
              paddingTop: "20px",
              paddingBottom: "35px",
            }}
          >
            {items?.map((item: IDataType, index: number) => (
              <GridBox
                key={item.id}
                data={item}
                firstName={item?.attributes?.full_name}
                lastName={item?.attributes?.last_name}
                image={item?.attributes?.Profile_Image?.data?.attributes?.url}
                avatar={""}
              />
            ))}
          </Box>
        </InfiniteScroll>
      </Box>
    )
  );
};

export default GridView;
