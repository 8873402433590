import { Avatar, Box, Typography } from "@mui/material";
import { IDataType } from "../exports/interfaces";
import { stringToColor } from "./EntryProfile";
import { stringAvatar } from "./EntryProfile";
import React from "react";
import Entry from "./Entry";

const GridBox = (props: {
  data: IDataType;
  firstName: string;
  lastName: string;
  image?: string;
  avatar: string;
}) => {
  
  const [Open, setOpen] = React.useState(false); 

  const fullName = `${props.firstName?.toLocaleUpperCase()} ${props.lastName?.toLocaleUpperCase()}`;
  const Image = props.avatar || props.image;

  const handleBoxClick = () => {
    setOpen(true);
  };


  return (
    <>
      <Box
        
        onClick={handleBoxClick} 
        sx={{
          width: "500px",
          height: "300px",
          bgcolor: "#EBF8F2",
          margin: "15px",
          marginLeft: "15px",
          border: "1px solid #D2FOE3",
          borderRadius: "10px",
          cursor:'pointer'
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            {...(!Image && props.firstName && props.lastName
              ? stringAvatar(fullName)
              : {})}
            src={`${process.env.REACT_APP_STRAPIURL}${props?.data?.attributes?.Profile_Image?.data?.attributes?.url}`}
            sx={{
              height: "72px",
              width: "72px",
              fontSize: "36px",
              marginLeft: "12px",
              bgcolor: !Image
                ? stringToColor(props.firstName + " " + props.lastName)
                : "inherit",
            }}
          />

          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography>
              {props.data.attributes.full_name +
                " " +
                props.data.attributes.last_name}
            </Typography>
            <Typography>{props.data.attributes.Phone}</Typography>
            <Typography>
              {props.data.attributes.Email ? props.data.attributes.Email : "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px dotted #AAAAAA",
            marginLeft: "12px",
            marginRight: "12px",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Subject Area</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.subject_area
                ? props.data.attributes.subject_area
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Expertise</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.expertise
                ? props.data.attributes.expertise
                : "-"}
            </Typography>
          </Box>
        </Box>
        {/* second line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
            bgcolor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Designation</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.Designation
                ? props.data.attributes.Designation
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Department</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.Department
                ? props.data.attributes.Department
                : "-"}
            </Typography>
          </Box>
        </Box>
        {/* third line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Organization</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.Organization
                ? props.data.attributes.Organization
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Sector</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.Sector
                ? props.data.attributes.Sector
                : "-"}
            </Typography>
          </Box>
        </Box>
        {/* fourth line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
            bgcolor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>URL</Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: props.data.attributes.Weblink ? "180px" : "unset",
                whiteSpace: props.data.attributes.Weblink ? "nowrap" : "normal",
              }}
            >
              {props.data.attributes.Weblink
                ? props.data.attributes.Weblink
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Referrer</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {props.data.attributes.Referrer
                ? props.data.attributes.Referrer
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Entry params={props.data} open={Open} setOpen={setOpen} />
    </>
  );
};

export default GridBox;
