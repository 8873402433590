import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Button,
  IconButton,
  Paper,
  Popper,
  styled,
  TextField,
  TextFieldVariants,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { headers } from "../Layout/Layout";

interface IExpertise {
  attributes: {
    expertise: string;
  };
  id: number;
}

export default function ExpertiseInput(props: {
  variant: TextFieldVariants;
  small?: boolean;
  spacing: string;
  selectedExpertise?: string[];
  setSelectedExpertise: Dispatch<SetStateAction<string[]>>;
}) {
  const [expertise, setExpertise] = useState<IExpertise[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [expertiseToDelete, setExpertiseToDelete] = useState<IExpertise | null>(null);


  const getExpertise = () => {
    axios
      .get(`${process.env.REACT_APP_STRAPIURL}/api/expertises`, {
        headers: headers,
      })
      .then((response) => setExpertise(response.data.data))
      .catch(() =>
        toast.error("Error fetching data", {
          position: "bottom-left",
        })
      );
  };

  useEffect(() => {
    getExpertise();
  }, []);

  
  const addNewExpertise = () => {
    const addExpertise = inputValue.trim();
    if (addExpertise) {
      axios
        .post(
          `${process.env.REACT_APP_STRAPIURL}/api/expertises`,
          { data: { expertise: addExpertise } },
          { headers: headers }
        )
        .then(() => {
          getExpertise();
          toast.success("Expertise added successfully", {
            position: "bottom-left",
          });
          setInputValue(""); 
        })
        .catch(() => {
          toast.error("Failed to add expertise", {
            position: "bottom-left",
          });
        });
    }
  };

  
  const handleDelete = () => {
    if (expertiseToDelete) {
      axios
        .delete(
          `${process.env.REACT_APP_STRAPIURL}/api/expertises/${expertiseToDelete.id}`,
          {
            headers: headers,
          }
        )
        .then(() => {
          getExpertise();
          toast.success("Expertise deleted successfully", {
            position: "bottom-left",
          });
        })
        .catch(() => {
          toast.error("Failed to delete expertise", {
            position: "bottom-left",
          });
        })
        .finally(() => {
          setOpen(false);
          setExpertiseToDelete(null);
        });
    }
  };

 
  const handleOpenDialog = (skill: IExpertise) => {
    setExpertiseToDelete(skill);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    setExpertiseToDelete(null);
  };

 
  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNewExpertise();
    }
  };

 
  const CustomPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      maxHeight: "175px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "1px",
      },
    },
  });

  return (
    <>
      <Autocomplete
        multiple
        disablePortal
        id="expertise-combo-box"
        size={props.small ? "small" : "medium"}
        options={expertise}
        getOptionLabel={(option) => option.attributes.expertise}
        onChange={(e, value) => {
          props.setSelectedExpertise(value.map((v) => v.attributes.expertise));
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "input") {
            setInputValue(value);
          } else if (reason === "clear") {
            setInputValue("");
          }
        }}
        inputValue={inputValue}
        value={expertise.filter((s) =>
          props.selectedExpertise?.includes(s.attributes.expertise)
        )}
        sx={{ width: props.spacing }}
        PopperComponent={(props) => <CustomPopper {...props} />}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={handleKey}
            variant={props.variant}
            label="Expertise"
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>{option.attributes.expertise}</span>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDialog(option);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </li>
        )}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              width: "200px !important",
            }}
          >
            {children}
            <Button
              color="primary"
              fullWidth
              sx={{ justifyContent: "flex-start", pl: 2 }}
              onMouseDown={(e) => {
                e.preventDefault(); 
                addNewExpertise();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  addNewExpertise();
                }
              }}
            >
              + Add New
            </Button>
          </Paper>
        )}
      />

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the expertise "
            {expertiseToDelete?.attributes.expertise}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} sx={{ color: "red" }} autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
