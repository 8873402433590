import { Dispatch, SetStateAction } from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { removeBorder } from "./Entry";

export default function Dropdown(props: {
  variant: TextFieldVariants;
  small?: boolean;
  spacing: string;
  setExpertise: Dispatch<SetStateAction<string>>;
  expertise?: string[];
  isEdit?: boolean;
}) {
  const value = props.expertise
    ? props?.expertise?.map((value: string, index: number) => {
        return ` ${value}`;
      })
    : "-";


  return (
    <>
      <TextField
        id="expertise"
        name="expertise"
        label="Expertise"
        size="small"
        sx={{
          ...(!props.isEdit ? removeBorder : { flex: 1 }),
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "black",
            color: "black",
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            color: "black",
          },
        }}
        variant="outlined"
        value={value}
        onChange={(e) => {
          props.setExpertise(e.target.value);
        }}
        disabled={!props.isEdit}
      />
    </>
  );
}
