import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import { DataContext, headers, LoadingContext } from "../Layout/Layout";
import { MobileCode } from "./MobileCode";
import AvatarProfile from "./DataFormProfile";
import SubjectInput from "./SubjectInput";
import ExpertiseInput from "./ExpertiseInput";
import SectorInput from "./SectorInput";

export default function Dataform(props: { handleClose: () => void }) {
  const { getEntries } = React.useContext(DataContext);
  const { setLoading } = React.useContext(LoadingContext);

  const [contact, setContact] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [selectedExpertise, setSelectedExpertise] = React.useState<string[]>(
    []
  );
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [weblink, setWeblink] = React.useState("");
  const [referrer, setReferrer] = React.useState("");
  const [image, setImage] = React.useState<FileList | null>(null);

  const [errors, setErrors] = React.useState({
    contact: "",
    lastName: "",
    email: "",
    designation: "",
    department: "",
    organization: "",
    weblink: "",
    referrer: "",
    phone: "",
    code: "",
    expertise: "",
  });

  const validate = (name: string, value: string) => {
    let error = "";
    if (
      ((name === "contact" || name === "lastName") && !value.trim()) ||
      (name === "phone" && !value?.split(/ (.+)/)[1]?.trim())
    ) {
      error = "This field is required.";
    } else if (
      [
        "contact",
        "lastName",
        "designation",
        "department",
        "organization",
        "referrer",
      ].includes(name) &&
      /\d/.test(value)
    ) {
      error = "Invalid input, no numbers allowed.";
    } else if (
      value !== "" &&
      name === "email" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ) {
      error = "Invalid email format.";
    } else if (
      name === "phone" &&
      !/^\D*(?:\d\D*){10,}$/.test(value?.split(/ (.+)/)[1])
    ) {
      error = "Invalid phone number.";
    } else if (
      value !== "" &&
      name === "expertise" &&
      !/^\d{1,2}$/.test(value)
    ) {
      error = "Invalid expertise";
    } else if (
      value !== "" &&
      name === "weblink" &&
      !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
    ) {
      error = "Invalid URL format.";
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  interface IFormData {
    full_name: string | null;
    last_name: string | null;
    sorting_value: string | null;
    subject_area: string | null;
    expertise: string[] | null;
    Phone: string | null;
    Email: string | null;
    Designation: string | null;
    Department: string | null;
    Organization: string | null;
    Sector: string | null;
    Weblink: string | null;
    Referrer: string | null;
    Profile_Image?: number;
  }

  const createEntry = (formData: IFormData) => {
    axios
      .post(
        `${process.env.REACT_APP_STRAPIURL}/api/entries`,
        { data: formData },
        { headers: headers }
      )
      .then((res) => {
        props.handleClose();
        toast.success("Success", {
          position: "bottom-left",
        });
        getEntries();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Failed", {
          position: "bottom-left",
        });
        setLoading(false);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    validate("contact", contact);
    validate("phone", phone);
    validate("lastName", lastName);

    if (
      !contact.trim() ||
      !phone.trim() ||
      !lastName.trim() ||
      errors.contact ||
      errors.phone ||
      errors.lastName
    ) {
      setLoading(false);
      return;
    }

    const imageForm = new FormData();

    if (image) {
      imageForm.append("files", image[0]);
      imageForm.append("ref", "entry");
      imageForm.append("field", "Profile_Image");
    }

    const formData: IFormData = {
      full_name: contact === "" ? null : contact,
      last_name: lastName === "" ? null : lastName,
      sorting_value: contact === "" ? null : contact?.toLowerCase(),
      subject_area: subject === "" ? null : subject?.toLowerCase(),
      expertise: selectedExpertise.length === 0 ? null : selectedExpertise,
      Phone: phone === "" ? null : phone,
      Email: email === "" ? null : email,
      Designation: designation === "" ? null : designation,
      Department: department === "" ? null : department,
      Organization: organization === "" ? null : organization,
      Sector: sector === "" ? null : sector?.toLowerCase(),
      Weblink: weblink === "" ? null : weblink,
      Referrer: referrer === "" ? null : referrer,
    };

    if (image) {
      axios
        .post(`${process.env.REACT_APP_STRAPIURL}/api/upload`, imageForm, {
          headers: headers,
        })
        .then((res) => {
          formData.Profile_Image = res.data[0].id;
          createEntry(formData);
        });
    } else {
      createEntry(formData);
    }

   

    setContact("");
    setLastName("");
    setSubject("");
    setSelectedExpertise([]);
    setPhone("");
    setEmail("");
    setDesignation("");
    setDepartment("");
    setOrganization("");
    setSector("");
    setWeblink("");
    setReferrer("");
    setImage(null);
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          alignContent: "center",
        }}
      >
        <AvatarProfile
          firstName={contact}
          lastName={lastName}
          setImage={setImage}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <TextField
          id="name"
          name="contact"
          label="First Name"
          size="small"
          required
          sx={{ flex: 1 }}
          variant="outlined"
          value={contact}
          onChange={(e) => {
            setContact(e.target.value);
            validate("contact", e.target.value);
          }}
          error={!!errors.contact}
          helperText={errors.contact}
        />
        <TextField
          id="name"
          name="contact"
          label="Last Name"
          size="small"
          sx={{ flex: 1 }}
          required
          variant="outlined"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            validate("lastName", e.target.value);
          }}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <MobileCode
          value={phone}
          required
          sx={{ flex: 1 }}
          onChange={setPhone}
          validate={validate}
          error={!!errors.phone}
          helperText={errors.phone}
        />
        {/* <TextField
          id="phone"
          name="phone"
          label="Mobile"
          variant="outlined"
          size="small"
          sx={{flex:1}}
          required
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            validate("phone", e.target.value);
          }}
          error={!!errors.phone}
          helperText={errors.phone}
        /> */}
        <TextField
          id="email"
          name="email"
          label="Email"
          size="small"
          variant="outlined"
          sx={{ flex: 1 }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validate("email", e.target.value);
          }}
          error={!!errors.email}
          helperText={errors.email}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <SubjectInput
          variant="outlined"
          spacing="100%"
          setSubject={setSubject}
          subject={subject}
          small
          
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <ExpertiseInput
          variant="outlined"
          spacing="100%"
          setSelectedExpertise={setSelectedExpertise}
          selectedExpertise={selectedExpertise}
          small
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <TextField
          id="designation"
          name="designation"
          label="Designation"
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
          value={designation}
          onChange={(e) => {
            setDesignation(e.target.value);
            validate("designation", e.target.value);
          }}
          error={!!errors.designation}
          helperText={errors.designation}
        />
        <TextField
          id="department"
          name="department"
          label="Department"
          size="small"
          sx={{ flex: 1 }}
          variant="outlined"
          value={department}
          onChange={(e) => {
            setDepartment(e.target.value);
            validate("department", e.target.value);
          }}
          error={!!errors.department}
          helperText={errors.department}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <TextField
          id="organization"
          name="organization"
          label="Organization"
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
          value={organization}
          onChange={(e) => {
            setOrganization(e.target.value);
            validate("organization", e.target.value);
          }}
          error={!!errors.organization}
          helperText={errors.organization}
        />
        <SectorInput
          variant="outlined"
          spacing="50%"
          setSector={setSector}
          sector={sector}
          small
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <TextField
          id="web"
          name="weblink"
          label="URL"
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
          value={weblink}
          onChange={(e) => {
            setWeblink(e.target.value);
            validate("weblink", e.target.value);
          }}
          error={!!errors.weblink}
          helperText={errors.weblink}
        />
        <TextField
          id="ref"
          name="referrer"
          label="Referrer"
          variant="outlined"
          size="small"
          value={referrer}
          sx={{ flex: 1 }}
          onChange={(e) => {
            setReferrer(e.target.value);
            validate("referrer", e.target.value);
          }}
          error={!!errors.referrer}
          helperText={errors.referrer}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Button
            type="submit"
            variant="contained"
            autoFocus
            sx={{
              color: "white",
              bgcolor: "rgb(2,136,209)",
              marginTop: "12px",
              mx: "auto",
              "&:hover":{
                bgcolor:"#1565c0"
              }
            }}
          >
            ADD
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
