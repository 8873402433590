import React from "react";
import SOS from "../Images/SOS.png";
import "../App.css";
import { Box, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Pop from "./Pop";

import Log from "./Log";
import Search from "./SearchIcon";

const Header: React.FC = () => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="Wrapper">
          <div style={{ display: "flex", alignItems:'center' }}>
            <img
              style={{ width: "35px", height: "35px" }}
              src={SOS}
              alt="logo"
              className="logo"
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: "550", paddingLeft: "10px" }}
            >
              <h6>Business Directory</h6>
            </Typography>
          </div>

          <Box className="group" sx={{ cursor: "pointer" }}>
            {/* <AddCircleIcon sx={{ fontSize: "32px" }} /> */}
            <Search />
            <Pop />
            <NotificationsIcon sx={{ height:'28px', width:'28px'  }} />
            <Log />
            {/* <AccountCircleIcon sx={{ fontSize: "32px" }} /> */}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Header;
