import React, { useState } from 'react';
import { IconButton, Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search: React.FC = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <Box>
      <IconButton onClick={handleSearchClick} sx={{padding:'0px'}}>
        <SearchIcon sx={{ height: '28px', width: '28px', color:'black'}} />
      </IconButton>

      {searchOpen && (
        <TextField
          variant="standard"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="medium"
        />
      )}

     
    </Box>
  );
};

export default Search;
