import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Popper,
  styled,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import { headers } from "../Layout/Layout";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeBorder } from "./Entry";

interface Isector {
  attributes: {
    sector: string;
  };
  id: number;
}

export default function Dropdown(props: {
  variant: TextFieldVariants;
  small?: boolean;
  spacing: string;
  setSector: Dispatch<SetStateAction<string>>;
  sector?: string;
  isEdit?: boolean;
}) {
  const [sectorArea, setSectorAreas] = useState<Isector[]>([]);
  const [valueAdd, setValueadd] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [sectorDelete, setSectorDelete] = useState<Isector | null>(null);

  const getSectorAreas = () => {
    axios
      .get(`${process.env.REACT_APP_STRAPIURL}/api/sectors`, {
        headers: headers,
      })
      .then((response) => setSectorAreas(response.data.data))
      .catch(() =>
        toast.error("Error fetching data", {
          position: "bottom-left",
        })
      );
  };

  useEffect(() => {
    getSectorAreas();
  }, []);

  const addNewValue = () => {
    if (valueAdd) {
      axios
        .post(
          `${process.env.REACT_APP_STRAPIURL}/api/sectors`,
          { data: { sector: valueAdd } },
          { headers: headers }
        )
        .then((response) => {
          getSectorAreas();
          toast.success("Sector added successfully", {
            position: "bottom-left",
          });
        })
        .catch((error) => {
          toast.error("Failed to add sector", {
            position: "bottom-left",
          });
        });
      setValueadd(undefined);
    }
  };

  const handleDelete = () => {
    if (sectorDelete) {
      axios
        .delete(
          `${process.env.REACT_APP_STRAPIURL}/api/sectors/${sectorDelete.id}`,
          {
            headers: headers,
          }
        )
        .then(() => {
          getSectorAreas();
          toast.success("Sector deleted successfully", {
            position: "bottom-left",
          });
        })
        .catch(() => {
          toast.error("failed to delete subject", {
            position: "bottom-left",
          });
        })
        .finally(() => {
          setOpen(false);
          setSectorDelete(null);
        });
    }
  };

  const handleDialog = (subject: Isector) => {
    setSectorDelete(subject);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSectorDelete(null);
  };

  const handleNewKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNewValue();
    }
  };

  const CustomPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      maxHeight: "175px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "1px",
      },
    },
  });



  return (
    <>
      <Autocomplete
        disablePortal
        disabled={!props.isEdit}
        id="combo-box-demo"
        size={props.small === true ? "small" : "medium"}
        options={sectorArea}
        getOptionLabel={(option) => option.attributes.sector}
        onChange={(e, value) => {
          if (value !== null) {
            props.setSector(value.attributes.sector);
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "clear") {
            props.setSector("");
          }
        }}
        value={
          sectorArea.find((area) => area.attributes.sector.toLowerCase() === props.sector?.toLowerCase()) ||
          (!props.isEdit ? { attributes: { sector: "-" }, id: 0 } : null)
        }
        sx={{ width: props.spacing }}
        PopperComponent={(props) => <CustomPopper {...props} />}
        renderInput={(params) => (
          <TextField
            onChange={(e) => setValueadd(e.target.value)}
            onKeyDown={handleNewKey}
            // value={props.isEdit ? props.sector|| "" : props.sector|| "-"}
            variant={props.variant}
            {...params}
            label="Sector"
            sx={{
              ...(!props.isEdit ? removeBorder : { flex: 1 }),
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                color: "black",
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "black",
              },
              "& .MuiAutocomplete-endAdornment": {
                display: props.isEdit ? "flex" : "none",
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>{option.attributes.sector}</span>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDialog(option);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </li>
        )}
        PaperComponent={({ children }) => {
          return (
            <Paper
              sx={{
                overflow: "hidden",
              }}
            >
              {children}
              <Button
                color="primary"
                fullWidth
                sx={{ justifyContent: "flex-start", pl: 2 }}
                onMouseDown={addNewValue}
                onKeyDown={addNewValue}
              >
                + Add New
              </Button>
            </Paper>
          );
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the subject "
            {sectorDelete?.attributes.sector}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} sx={{ color: "red" }} autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
